<template>
  <div id="container">
    <svg
      id="play"
      viewBox="0 0 100 100"
      @click="consulterConference(path)"
    >
      <path
        class="stroke-solid"
        fill="none"
        stroke="#e91e62"
        d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
    C97.3,23.7,75.7,2.3,49.9,2.5"
      />
      <path
        class="stroke-dotted"
        fill="none"
        stroke="#e91e62"
        d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
    C97.3,23.7,75.7,2.3,49.9,2.5"
      />
      <path
        class="icon"
        fill="#e91e62"
        d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"
      />
    </svg>
    <!-- <v-btn
      block
      color="primary"
    >
      {{ $t('presentation.acceder_a_la_conference') }}
    </v-btn> -->
    <label
      id="title"
      class="text-h3 d-block"
    >{{ $t('presentation.acceder_a_la_conference') }}</label>
  </div>
</template>
<style scoped lang="scss">

#container {
  width:fit-content;
  text-align:center;
  cursor: pointer;
}

// Spin animation
@keyframes spin {
  to { transform: rotate(360deg); }
}

.stroke-dotted {
  opacity: 0;
  stroke-dasharray: 4,5;
  stroke-width: 1px;
  transform-origin: 50% 50%;
  animation: spin 4s infinite linear;
  transition: opacity 1s ease,
              stroke-width 1s ease;
}
.stroke-solid {
  stroke-dashoffset: 0;
  stroke-dashArray: 300;
  stroke-width: 4px;
  transition: stroke-dashoffset 1s ease,
              opacity 1s ease;
}

.icon {
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
}

// Hover
#container:hover {
  .stroke-dotted {
    stroke-width: 4px;
    opacity: 1;
  }
  .stroke-solid {
    opacity: 0;
    stroke-dashoffset: 300;
  }
  .icon {
    transform: scale(1.05);
  }
  #title {
      color: #d98ba5;
      transition: 1s;
  }
}

#play {
  cursor: pointer;
  margin:auto;
}
  #title {
      color: #e91e62;
      transition: 1s;
  }
#container svg {
  margin:auto;
  width: 90px;
}

</style>
<script>

  export default {
    name: 'CnxPlayButton',

    components: {
    },
    props: {
      path: String,
    },

    computed: {
    },

    methods: {
      consulterConference (path) {
        this.$router.push({ path: path })
      },
    },
  }
</script>
