<template>
  <base-material-card
    color="success"
    inline
    class="px-5 py-3 mb-0 mb-sm-5"
    :title="conference.nom"
  >
    <div>
      <v-icon class="px-2 pb-3">
        mdi-clock-outline
      </v-icon>
      <div style="display:inline-block">
        <p>{{ $d(new Date(conference.dateOuverture), 'long') }}</p>
        <p>{{ durée }}</p>
      </div>
    </div>
    <div>
      <div
        class="fill-height overflow-hidden"
        fluid
      >
        <!-- ***************** -->
        <!-- EN ATTENTE -->
        <div
          v-if="état == 'ATTENTE'"
          class="compteur mt-8"
        >
          {{ compteur }}
        </div>
        <v-img
          v-if="état == 'ATTENTE'"
          class="mb-4"
          :src="urlImage(conference)"
          max-height="300"
        />

        <!-- ***************** -->
        <!-- EN COURS -->

        <v-img
          v-if="état == 'EN COURS'"
          class="my-4"
          :src="urlImage(conference)"
          max-height="240"
        >
          <!-- <v-row
            v-if="état == 'EN COURS'"
            align="right"
            justify="center"
            style="height:260px;"
          > -->
          <v-card
            style="float:right"
            class="mt-1 mr-1 elevation-6"
            @click="consulterConference"
          >
            <v-card-title>
              {{ $t('presentation.en_direct') }}
            </v-card-title>
            <v-card-text>
              <cnx-video
                :url-presentation="conference.urlPresentation"
                :preview="true"
              />
            </v-card-text>
          </v-card>
          <!-- </v-row> -->
        </v-img>

        <cnx-play-button
          v-if="état == 'EN COURS'"
          class="mx-auto"
          :path="'/orka/vision/' + conference.id"
        />
        <!-- ***************** -->
        <!-- TERMINÉ -->
        <v-img
          v-if="état == 'TERMINÉ'"
          class="my-4"
          :src="urlImage(conference)"
          max-height="300"
        >
          <div
            class="compteur my-8"
          >
            {{ $t('presentation.la_conférence_est_terminée') }}
          </div>
        </v-img>
      </div>
    </div>
  </base-material-card>
</template>

<style scoped>
.compteur {
  color: white;
  padding-left: 8px;
  font-size: 20px;
  background-color: #00000090;
}
</style>
<script>
  import horlogeService from '@/services/horlogeService.js'
  import moment from 'moment'
  import CnxVideo from '../composantes/CnxVideoPlayer'
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'
  import CnxPlayButton from '../composantes/CnxPlayButton'

  export default {
    name: 'AttenteConference',
    components: {
      CnxVideo,
      CnxPlayButton,
    },

    props: {
      conference: Object,
    },
    data: () => ({
      currentTime: null,
      dateOuverture: null,
      dateFermeture: null,
      compteur: '',
      durée: '',
      état: '',
      timeout: null,
    }),
    mounted() {
      this.dateOuverture = Date.parse(this.conference.dateOuverture)
      this.dateFermeture = Date.parse(this.conference.dateFermeture)
      this.dateDebut = Date.parse(this.conference.dateDebut)
      this.dateFin = Date.parse(this.conference.dateFin)

      this.changerEtat()

      var duration = moment.duration(this.dateFin - this.dateDebut)
      this.durée = this.momentHumanize(duration.asMinutes(), 'minutes')
      console.log('XXX', this.durée)

      console.log('conf', this.conference)

      this.timeout = setInterval(this.tic, 1000)
    },
    beforeDestroy() {
      clearInterval(this.timeout)
    },
    methods: {
      tic() {
        this.compteur = i18n.t('presentation.debute') + ' ' + moment(this.dateOuverture).locale(this.$store.state.appLanguage).from(horlogeService.maintenant())
        this.changerEtat()
      },
      urlImage(conference) {
        return restApiService.mediaUrl(conference.mediaImage)
      },
      changerEtat() {
        const now = horlogeService.maintenant()
        if (this.dateOuverture > now) {
          this.état = 'ATTENTE'
        }
        if (this.dateOuverture < now && now < this.dateFermeture) {
          this.état = 'EN COURS'
        }
        if (this.dateFermeture < now) {
          this.état = 'TERMINÉ'
        }
      },
      consulterConference() {
        this.$router.push({ path: '/orka/vision/' + this.conference.id })
      },
      momentHumanize(eventDuration, unit) {
        var eventMDuration = moment.duration(eventDuration, unit)
        var eventDurationArray = []

        if (parseInt(eventMDuration.asDays()) > 0) {
          var jours = parseInt(eventMDuration.asDays())

          if (eventMDuration.hours() >= 12) {
            jours = jours + 1
          }

          var strJours = jours > 1 ? i18n.t('duree.jours') : i18n.t('duree.jour')
          eventDurationArray.push(parseInt(jours) + strJours)
        } else if (parseInt(eventMDuration.asDays()) === 0) {
          if (eventMDuration.hours() > 0) {
            if (eventMDuration.hours() > 1) {
              eventDurationArray.push(eventMDuration.hours() + i18n.t('duree.heures'))
              eventMDuration.subtract(eventMDuration.hours(), 'hours')
            } else {
              eventDurationArray.push(eventMDuration.hours() + i18n.t('duree.heure'))
              eventMDuration.subtract(eventMDuration.hours(), 'hour')
            }
          }
          if (eventMDuration.minutes() > 0) {
            if (eventMDuration.minutes() > 1) {
              eventDurationArray.push(eventMDuration.minutes() + i18n.t('duree.minutes'))
            } else {
              eventDurationArray.push(eventMDuration.minutes() + i18n.t('duree.minute'))
            }
          }
        }

        return eventDurationArray.length === 1 ? eventDurationArray[0]
          : eventDurationArray.join(' et ')
      },
    },
  }
</script>
