<template>
  <v-container
    fluid
    data-cy="pageSallePresentation"
  >
    <h1
      class="font-weight-light mb-4 text-h5"
    >
      {{ evenement.nom }}
    </h1>
    <v-row
      v-for="conference in conferences"
      :key="conference.id"
    >
      <v-col
        cols="12"
        class="col-sm-8 col-md-6"
      >
        <boite-conference
          :conference="conference"
          :evenement="evenement"
        />
      </v-col>
      <v-col
        cols="10"
        class="col-sm-4 col-md-6"
      >
        <v-card class="mt-0 mt-sm-7">
          <v-card-title>{{ $t('general.info') }}</v-card-title>
          <v-card-text>
            <div v-html="conference.description" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  // import axios from 'axios'
  import BoiteConference from '../composantes/BoiteConference'
  import restApiService from '@/services/restApiService.js'
  import horlogeService from '@/services/horlogeService.js'

  export default {
    components: {
      BoiteConference,
    },
    data: () => ({
      evenement: {},
      conferences: [],
    }),
    created () {
      this.$moment.locale('fr')

      restApiService.get(`/api/evenements/${this.$route.params.idEvenement}`)
        .then((result) => {
          this.evenement = result.data
        })
        .catch((erreur) => alert(erreur))

      restApiService.get(`/api/webUser/listeConferencesAccessibles/${this.$route.params.idEvenement}`)
        .then((result) => {
          this.conferences = result.data.sort((a, b) => {
            if (a.dateDebut < b.dateDebut) {
              return -1
            }
            if (a.dateDebut > b.dateDebut) {
              return 1
            }
            if (a.dateDebut === b.dateDebut) {
              if (a.nom < b.nom) {
                return -1
              }
              if (a.nom > b.nom) {
                return 1
              } else {
                return 0
              }
            }
          },
          ).filter(c => Date.parse(c.dateFin) > horlogeService.maintenant())
        })
        .catch((erreur) => alert(erreur))
    },
  }
</script>
